<template>
  <div class="menu">
    <img src="@/assets/menu-head.jpg" style="width:100%" />
    <van-collapse v-model="activeNames" accordion>
      <template v-for="(item, index) in list" :key="index">
        <div v-if="!item?.child" class="item" @click="toVideo(item)">
          <div
            class="van-cell van-cell--clickable van-collapse-item__title"
            role="button"
            tabindex="0"
            aria-expanded="false"
          >
            <div class="van-cell__title">
              <div class="item-title">
                <img class="item-icon" :src="item.icon" />
                <span>{{ item.title }}</span>
              </div>
            </div>
            <i
              class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon"
              style="transform: rotate(270deg)"
            >
            </i>
          </div>
        </div>
        <van-collapse-item v-else class="item" :name="index">
          <template #title>
            <div class="item-title">
              <img class="item-icon" :src="item.icon" />
              <span> {{ item.title }}</span>
            </div>
            <div class="item-title-click"></div>
          </template>
          <div style="width: 82vw"></div>
          <template v-if="item?.child">
            <div
              class="item2"
              v-for="(item2, index2) in item.child"
              :key="`${index}-${index2}`"
            >
              <div
                class="item2-item"
                v-if="!item2?.child"
                @click="toVideo(item2)"
              >
                {{ item2.title }}
              </div>
              <van-collapse v-else v-model="item2.active" accordion>
                <div class="item2-item" style="font-weight: bold">
                  {{ item2.title }}
                </div>
                <van-divider style="margin: 0" />
                <div
                  v-for="(item3, index3) in item2.child"
                  :key="`${index}-${index2}-${index3}`"
                >
                  <div class="item2-item" @click="toTest(item3?.testId)">
                    {{ item3.title }}
                  </div>
                  <van-divider style="margin: 0" />
                </div>
              </van-collapse>
            </div>
          </template>
        </van-collapse-item>
      </template>
    </van-collapse>
  </div>
</template>

<script>
import userMenu from "../data/userMenu";
import adminMenu from "../data/adminMenu";

export default {
  name: "Home",
  components: {},
  data: () => ({
    activeNames: null,
    list: [],
  }),
  mounted() {
    if ("1" == this.$route.query?.type) {
      this.list = adminMenu;
    } else {
      this.list = userMenu;
    }
  },
  methods: {
    toVideo(item) {
      let menu = this.$route.query?.type;
      this.$router.push({ path: "/video", query: { id: item?.video_id, menu: menu } });
    },
    toTest(id) {
      this.$router.push({
        path: "/test",
        query: { menu: this.$route.query?.type, id: id },
      });
    },
  },
};
</script>

<style>
.menu {
  height: 100%;
  width: 100%;
  background-color: #f2f0eb;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.item {
  width: 89.4vw;
  overflow: hidden;
  background: #ffffff;
  border-radius: 3.2vw;
  margin: 0 5.3vw 3vw 5.3vw;
  box-shadow: 0px 0px 13px 0px rgba(153, 153, 153, 0.1);
}

.item > div {
  display: flex;
  align-items: center;
  padding: 0 3vw !important;
}

.item-icon {
  width: 12vw;
  height: 12vw;
  margin-left: -2vw;
}

.item-title {
  font-weight: 400;
  font-size: 4vw;
  color: #000;
  height: 17vw;
  display: flex;
  align-items: center;
}

.item2 {
  padding-left: 13.5vw;
}

.item2-item {
  color: #000000;
  font-size: 4vw;
  height: 11vw;
  width: 100%;
  display: flex;
  align-items: center;
}

.van-collapse-item__content {
  padding: 0;
}

.item-title-click {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
