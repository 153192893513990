export default [
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/10.png",
        video_id: 1,
        title: "经理功能界面介绍",
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/11.png",
        video_id: 2,
        title: "设置培训模式",
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/12.png",
        video_id: 3,
        title: "设置员工卡及伙伴借调",
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/13.png",
        video_id: 4,
        title: "现金管理-找零金分配",
    },
]