export default [
    // {
    //     icon: "",
    //     title: "系统登录界面介绍",
    // },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/2.png",
        title: "交互式点单",
        active: null,
        child: [
            {
                title: "点单操作",
                video_id: 5,
            },
            {
                title: "使用好礼操作",
                video_id: 6,
            },
        ],
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/3.png",
        title: "退货",
        video_id: 7,
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/4.png",
        title: "报损，试吃，伙伴饮料",
        video_id: 8,
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/5.png",
        title: "会员卡、星礼卡SVC",
        active: null,
        child: [
            {
                title: "会员卡的查询和使用",
                video_id: 9,
            },
            {
                title: "星礼包SR Kit售卖激活绑定及退卡",
                video_id: 10,
            },
            {
                title: "SVC的查询",
                video_id: 11,
            },
            {
                title: "SVC的激活及退卡",
                video_id: 12,
            },
            {
                title: "SVC的充值及取消充值",
                video_id: 13,
            },
        ],
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/6.png",
        title: "券类及B2B券售卖及兑换",
        active: null,
        child: [
            {
                title: "券类使用",
                video_id: 14,
            },
            {
                title: "伙伴折扣",
                video_id: 15,
            },
            {
                title: "诚意券",
                video_id: 16,
            },
        ],
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/7.png",
        title: "报表和卡券功能介绍",
        video_id: 17,
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/8.png",
        title: "点钞及关闭钱箱",
        video_id: 18,
    },
    {
        icon: "https://fy-static-res.oss-cn-hangzhou.aliyuncs.com/UBS/%E6%98%9F%E7%88%B8%E7%88%B8/9.png",
        title: "测试",
        active: null,
        child: [
            {
                title: "系统登陆",
                active: null,
                child: [
                    {
                        title: "题目1",
                        testId: 0,
                    },
                ],
            },
            {
                title: "交互",
                active: null,
                child: [
                    {
                        title: "题目1",
                        testId: 1,
                    },
                    {
                        title: "题目2",
                        testId: 2,
                    },
                    {
                        title: "题目3",
                        testId: 3,
                    },
                ],
            },
            {
                title: "场景练习",
                active: null,
                child: [
                    {
                        title: "题目1",
                        testId: 4,
                    },
                    {
                        title: "题目2",
                        testId: 5,
                    },
                    {
                        title: "题目3",
                        testId: 6,
                    },
                    {
                        title: "题目4",
                        testId: 7,
                    },
                ],
            },
        ],
    },
]